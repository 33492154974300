import Container from "../../components/container/Container"
import React, { Component, FormEvent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch, AnyAction, ActionCreator } from 'redux'
import { CatPageState, RANDOMISER_TYPES, RANDOMISER_TYPE_DESCRIPTION } from '../../reducers/cat-page-reducer'
import { STORE_STATE } from '../../reducers'
import { addCat, removeCat, addNumberRange, AddCatAction, selectRandom, RemoveCatAction, toggleType, ToggleTypeAction, SelectRandomAction, toggleShowResults, ToggleShowResultsAction, AddNumberRangeAction } from '../../actions/cat-actions'
import CatTable from "./cat-table"
import styled from 'styled-components'

import _ from 'lodash'
import Row from '../../components/container/Row'
import Cat from '../../model/cat'
import matIconAdd from '../../static/svg/outline-add-24px.svg'
import RandomizerResult from "./randomiser-result";

class CatList extends Component<CatPageState & CatDispatchProps> {
  constructor(props: any) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.lastButtonOnClick = this.lastButtonOnClick.bind(this)
  }

  public handleSubmit(event: FormEvent) {
    event.preventDefault()
    const { addCat, randomiserType, addNumberRange } = this.props
    const value = (this.refs.newInput as any).value;
    if (value) {
      if (randomiserType === RANDOMISER_TYPES.FROM_LIST){
        addCat(value);
        (this.refs.newInput as any).value = '';
      } else if (randomiserType === RANDOMISER_TYPES.FROM_RANGE) {
        addNumberRange(value);
      }
    }
  }

  public render() {
    const { cats, randomiserType, showResults, selection } = this.props
    const headers = _.keys(cats[0])

    return (
      <CatListContent className="main-content-container px-5 py-3">
        <h3 className="page-title">Cat List</h3>
        <Container fluid={true}>
          <Row noGutters={true}>
            <div className="d-flex justify-content-between justify-content-sm-start col-xs-12 col-md-9 mb-2">
              <label className="align-self-center mr-3">Select From:</label>
              <div className="randomiser-description col-5 mr-auto">{RANDOMISER_TYPE_DESCRIPTION[randomiserType]}</div>
              <button className="btn btn-secondary" onClick={() => this.toggleType()}>{randomiserType}</button>
            </div>
            <form onSubmit={this.handleSubmit} ref="addInputForm" className="col-xs-12 col-md-10">
              <div className="form-group">
                <label>Cat Name:</label>
                <div className="d-flex">
                  <input className="form-control" type='text' name='name' ref='newInput' />
                  <MatAddIcon onClick={(e) => this.submitForm(e)} alt="Add To List" className="ml-2" src={matIconAdd}></MatAddIcon>
                </div>
              </div>
              <div className="d-flex">
                <button className="btn btn-select" type='button' onClick={() => this.selectRandom()}>Select Cat</button>
              </div>
              <br />
            </form>
          </Row>
          <Row>
            <RandomizerResult
              showResults={showResults}
              results={selection || ''}
            ></RandomizerResult>
          </Row>
          {
            randomiserType === RANDOMISER_TYPES.FROM_LIST ?
              <Row>
                <CatTable
                  className="col-xs-12 col-md-10"
                  tableData={cats}
                  tableHeaders={headers}
                  oneIndex={true}
                  lastButton={{
                    text: "Remove",
                    onClick: this.lastButtonOnClick
                  }} />
              </Row> : ''
          }
        </Container>
      </CatListContent>
    )
  }

  private submitForm(e: FormEvent) {
    this.handleSubmit(e)
  }

  private selectRandom() {
    const { toggleShowResults, selectRandom, showResults } = this.props
    if (!showResults) {
      toggleShowResults()
    }
    selectRandom()
  }

  private toggleType() {
    const { toggleType, showResults } = this.props
    toggleType()
    if (showResults) {
      toggleShowResults()
    }
  }

  public lastButtonOnClick(event: MouseEvent, catToRemove: Cat, index: number) {
    const { removeCat, showResults } = this.props
    removeCat(index)
    if (showResults) {
      toggleShowResults()
    }
  }
}

const MatAddIcon = styled.img`
  :focus{
    outline: blue
  }
`

const CatListContent = styled.div`
  .randomiser-description {
    color: #8c8c8c;
    font-size: 12px;
  }
  .no-gutters {
    margin-left: -15px;
    margin-right: -15px;
  }

  .btn-select {
    color: #fff;
    font-weight: bold;
    /* text-transform: uppercase; */
    /* letter-spacing: 0.04rem; */
    background-color: #0a48ff;

    :hover{
      color: #fff;
    }
  }
`

type CatDispatchProps = {
  addCat: ActionCreator<AddCatAction>,
  addNumberRange: ActionCreator<AddNumberRangeAction>,
  removeCat: ActionCreator<RemoveCatAction>,
  toggleType: ActionCreator<ToggleTypeAction>,
  selectRandom: ActionCreator<SelectRandomAction>,
  toggleShowResults: ActionCreator<ToggleShowResultsAction>
}

const mapStateToProps = ({ catPage }: STORE_STATE) => {
  return {
    cats: catPage!.cats,
    showResults: catPage!.showResults,
    randomiserType: catPage!.randomiserType,
    selection: catPage!.selection,
    numberRange: catPage!.numberRange
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators(
    {
      addCat,
      removeCat,
      addNumberRange,
      toggleType,
      selectRandom,
      toggleShowResults
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CatList)