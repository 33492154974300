import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components';

const Row = (props: RowProps) => {
  const {
    form,
    noGutters,
    className,
    ...attrs
  } = props

  const classes = classNames(
    className,
    form ? 'form-row' : 'row',
    noGutters ? 'no-gutters' : null
  )

  return (<StyledRow {...attrs} className={classes} />)
}

const StyledRow = styled.div`
`

type RowProps = {
  /**
   * The class name.
   */
  className?: string
  /**
   * Whether it has gutters, or not.
   */
  noGutters?: boolean
  /**
   * Whether it is located inside a form, or not.
   */
  form?: boolean
  /**
   * The component's children type.
   */
  children: any[] | any
}

export default Row
