import React, { Component } from 'react'
import styled from 'styled-components'
import { Line as LineChart } from 'react-chartjs-2'

export class CatGraph extends Component {
  public render() {

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const datasets = [
      {
        label: 'My First dataset',
        fillColor: 'rgba(220,220,220,0.2)',
        strokeColor: 'rgba(220,220,220,1)',
        pointColor: 'rgba(220,220,220,1)',
        pointStrokeColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(220,220,220,1)',
        data: [65, 59, 80, 81, 56, 55, 40],
      },
      {
        label: 'My Second dataset',
        fillColor: 'rgba(151,187,205,0.2)',
        strokeColor: 'rgba(151,187,205,1)',
        pointColor: 'rgba(151,187,205,1)',
        pointStrokeColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(151,187,205,1)',
        data: [28, 48, 40, 19, 86, 27, 90],
      }
    ];

  const options = {
    type: 'line',
    data: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
        label: 'Unfilled',
        fill: false,
        backgroundColor: "#0000FF",
        borderColor: "#0000FF",
        data: [78, -2, -40, 55, -53, -88, 20],
      }, {
        label: 'Dashed',
        fill: false,
        backgroundColor: "#00FF00",
        borderColor: "#00FF00",
        borderDash: [5, 5],
        data: [45, -92, 57, -24, 32, 49, 24],
      }, {
        label: 'Filled',
        backgroundColor: "#FF0000",
        borderColor: "#FF0000",
        data: [-34, 80, 71, -5, 32, 1, 0],
        fill: true,
      }]
    },
    options: {
      responsive: true,
      title: {
        display: true,
        text: 'Chart.js Line Chart'
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Month'
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Value'
          }
        }]
      }
    }
  };
  return(
    <ScrollWrapper>
      <StyledDiv>
        <LineChart
          data={{ labels, datasets }}
          options={options}
          />
      </StyledDiv > 
    </ScrollWrapper>
    )
  }
}

const ScrollWrapper = styled.div`
  width: inherit;
  overflow-x: scroll;
`

const StyledDiv = styled.div`
  border: '1px solid black';
  padding: '15px';
  width: 600px;
  height: 600px;
`
export default CatGraph
