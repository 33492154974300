import React, { Component } from 'react'
import styled from 'styled-components'
const cat = require('../../static/images/bongo-cat.gif')

export class InteractiveCatComponent extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      imageList: [
        require('../../static/images/kfc-bucket.png'),
        require('../../static/images/weetameal.png'),
        require('../../static/images/eggette.png'),
        ''
      ],
      imageA: '',
      imageB: '',
      toggle: true,
      currentIndex: 0,
      totalToggleStates: 0
    }
  }
  render() {
    return (
      <StyledImgParent>
        <StyledImg src={cat} />
        <StyledInvisibleTriggerBox onClick={() => this.onTriggerBoxClick()} />
        {this.state.imageA ? <StyledImgOnTop src={`${this.state.imageA}`} className={`placeholder-a ${this.state.toggle ? 'ontop' : 'exit'}`} /> : ''}
        {this.state.imageB ? <StyledImgOnTop src={`${this.state.imageB}`} className={`placeholder-b ${this.state.toggle ? 'exit' : 'ontop'}`} /> : ''}
      </StyledImgParent>
    )
  }

  onTriggerBoxClick() {
    // determines if placeholder-a is responsible for rendering the new image or not. placeholder-b otherwise
    const isPlaceholderANewImage = (this.state.totalToggleStates + 1) % 2;
    const newIndex = (this.state.currentIndex + 1) % this.state.imageList.length;
    const newTotalToggleStates = this.state.totalToggleStates + 1;
    const newImage = this.state.imageList[newIndex];

    if (isPlaceholderANewImage) {
      this.setState({ ...this.state, imageB: newImage, currentIndex: newIndex, totalToggleStates: newTotalToggleStates, toggle: !this.state.toggle });
    } else {
      this.setState({ ...this.state, imageA: newImage, currentIndex: newIndex, totalToggleStates: newTotalToggleStates, toggle: !this.state.toggle });
    }
  }
}

const StyledImgParent = styled.div`
  position: relative;
  display: inline-block;
`

const StyledImg = styled.img`
  object-fit: scale-down;
  max-width: 100%;
  
  @media (min-width: 768px) {
    height: 400px;
  }
`

const StyledImgOnTop = styled.img`
  position: absolute;
  height: 100px;
  
  ::selection {
    background: transparent;
  }

  &.ontop {
    visibility: visible;
    top: 62.5%;
    left: 18.5%;
    -webkit-animation: transitionIn ease-out 0.4s;
    animation: transitionIn ease-out 0.4s;

    @keyframes transitionIn{
      0% { top: 77%; left: 0%; opacity: 0; }
      100% {top: 62.5%; left: 18.5%; opacity: 1; }
    }
  }

  &.exit {
    visibility: hidden;
    top: 77%;
    left: 0%;
    visibility:hidden;
    -webkit-animation: transitionOut ease-out 0.4s;
    animation: transitionOut ease-out 0.4s;

    @keyframes transitionOut{
      0% {top: 62.5%; left: 18.5%;  visibility:visible; opacity: 1;}
      100% { top: 77%; left: 0%; visibility:hidden; opacity: 0;}
    }
  }
`

const StyledInvisibleTriggerBox = styled.div`
  position: absolute;
  width: 120px;
  height: 110px;
  background-color: transparent;
  opacity: 0.5;
  top: 34%;
  left: 32%;
`

export default InteractiveCatComponent
