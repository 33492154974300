import Container from "../../components/container/Container";
import React, { useEffect } from 'react';
import { getHomeTitle } from '../../util/home-titles';
import InteractiveCatComponent from "../../components/interactive-cat";
import styled from 'styled-components';

const Home = () => {

  useEffect(() => {
    document.title = 'CK, ' + getHomeTitle();
  });

  return (
    <Container fluid={true} className="main-content-container px-4 py-3">
      <div>
        <h2 className='font-weight-bold mb-4'>CK</h2 >
        <div>
          <p>Homepage of Chee Khuin. <br /><UnderlineBoldDiv>Do not</UnderlineBoldDiv> pet the cat.</p>
        </div>
        <InteractiveCatComponent />
      </div>
    </Container>
  )
}

const UnderlineBoldDiv = styled.span`
  text-decoration: underline;
  font-weight: bold;
  /* text-transform: uppercase; */
`


export default Home