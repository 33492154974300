import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { colours } from '../../../../static/style/style-constants'
import { connect } from 'react-redux';
import {toggleSidebar, ToggleSidebarAction} from '../../../../actions/app-actions'
import { AnyAction, bindActionCreators, ActionCreator, Dispatch } from 'redux';

const SidebarRoute = (props: SidebarRouteProps) => {
  const { title, path, glyphicon } = props
  const iconClass = glyphicon ? ` glyphicon glyphicon-${glyphicon}` : ""

  return (
    <StyledRoute>
      <Link 
        className="sidebar-route nav-item"
        // onClick={() => setImmediate(toggleSidebar)}
        to={path}>
        <span className={glyphicon ? iconClass : ""} />
        <div className="nav-link">{title}</div>
      </Link>
    </StyledRoute>
  )
}

const StyledRoute = styled.div`
  a {
    text-decoration: none !important; // bootstrap
  }
  .nav-item:hover {
      .nav-link {
        color: ${colours.black};
        transition: .3s;
      }
  }
  .nav-item {
    .nav-link {
      color: ${colours.greyMedium};
      transition: 0.3s;
    }
  }

  @media (max-width: 767.98px) { // sm breakpoint, aligns items to right
    .nav-link {
      text-align: right;
    }
  }
`

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators(
    { toggleSidebar },
    dispatch
  )
}

type SidebarRouteProps = {
  title: string | undefined
  path: string
  glyphicon: string | undefined,
  toggleSidebar: ActionCreator<ToggleSidebarAction>
}

export default connect(null, mapDispatchToProps)(SidebarRoute);