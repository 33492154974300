import Cat from '../model/cat';
import { ActionCreator } from 'redux';

export enum CatActionTypes {
  ADD_CAT = "ADD_CAT",
  REMOVE_CAT = "REMOVE_CAT",
  TOGGLE_TYPE = "TOGGLE_TYPE",
  SELECT_RANDOM = "SELECT_RANDOM",
  TOGGLE_SHOW_RESULTS = "TOGGLE_SHOW_RESULTS",
  ADD_NUMBER_RANGE = "ADD_NUMBER_RANGE"
}
export const addCat: ActionCreator<AddCatAction> = (catName: string) => {
  console.log('inside action creator')
  const newCat = new Cat(catName)
  return { type: CatActionTypes.ADD_CAT, payload: newCat }
}

export const addNumberRange: ActionCreator<AddNumberRangeAction> = (number: number) => {
  return { type: CatActionTypes.ADD_NUMBER_RANGE, payload: number}
}

export const removeCat: ActionCreator<RemoveCatAction> = (catNumber: number) => {
  return { type: CatActionTypes.REMOVE_CAT, payload: catNumber }
}

export const toggleType: ActionCreator<ToggleTypeAction> = () => {
  return { type: CatActionTypes.TOGGLE_TYPE }
}

export const selectRandom: ActionCreator<SelectRandomAction> = () => {
  return { type: CatActionTypes.SELECT_RANDOM }
}

export const toggleShowResults: ActionCreator<ToggleShowResultsAction> = () => {
  return { type: CatActionTypes.TOGGLE_SHOW_RESULTS }
}

export interface AddCatAction {
  type: CatActionTypes.ADD_CAT
  payload: Cat
}

export interface AddNumberRangeAction {
  type: CatActionTypes.ADD_NUMBER_RANGE
  payload: number
}

export interface RemoveCatAction {
  type: CatActionTypes.REMOVE_CAT
  payload: number
}

export interface ToggleTypeAction {
  type: CatActionTypes.TOGGLE_TYPE
}

export interface SelectRandomAction {
  type: CatActionTypes.SELECT_RANDOM
}

export interface ToggleShowResultsAction {
  type: CatActionTypes.TOGGLE_SHOW_RESULTS
}

export type CatActions = AddCatAction | RemoveCatAction | ToggleTypeAction | SelectRandomAction | ToggleShowResultsAction | AddNumberRangeAction

