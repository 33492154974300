import { CatActions, CatActionTypes } from '../actions/cat-actions';
import Cat from '../model/cat';

export enum RANDOMISER_TYPES {
  FROM_LIST = 'List',
  FROM_RANGE = 'Number'
}

export const RANDOMISER_TYPE_DESCRIPTION = {
  [RANDOMISER_TYPES.FROM_LIST]: '        ',
  [RANDOMISER_TYPES.FROM_RANGE]: 'Selects between 1 to input number'
}

const initialState = {
  cats: [
    new Cat('meow1')
  ],
  numberRange: 0,
  showResults: false,
  randomiserType: RANDOMISER_TYPES.FROM_LIST,
  selection: ''
}

const catPageReducer = (state = initialState, action: CatActions) => {
  const catArray = state.cats
  const { randomiserType } = state
  let newCatArray = [...catArray]

  switch (action.type) {
    case CatActionTypes.ADD_CAT:
      newCatArray = [
        ...catArray,
        action.payload
      ]
      return { ...state, cats: newCatArray }

    case CatActionTypes.ADD_NUMBER_RANGE:
      return { ...state, numberRange: action.payload }

    case CatActionTypes.REMOVE_CAT:
      newCatArray = catArray.filter((cat, index) => {
        // return only cats without names equal to name
        // also make sure the payload exists using a null check
        return index !== action.payload
      })
      return { ...state, cats: newCatArray }

    case CatActionTypes.TOGGLE_TYPE:
      const oldIndex = Object.values(RANDOMISER_TYPES).indexOf(state.randomiserType)
      const newIndex = (oldIndex + 1) % Object.keys(RANDOMISER_TYPES).length
      const newKey = Object.keys(RANDOMISER_TYPES)[newIndex]
      // typescript should have better enum support because this looks horrible really
      return { ...state, randomiserType: RANDOMISER_TYPES[newKey as keyof typeof RANDOMISER_TYPES] as RANDOMISER_TYPES }

    case CatActionTypes.SELECT_RANDOM:
      switch (randomiserType) {
        case RANDOMISER_TYPES.FROM_LIST:
          const newSelection = state.cats[Math.floor(Math.random() * state.cats.length)]
          return { ...state, selection: newSelection.name }

        case RANDOMISER_TYPES.FROM_RANGE:
          const numberSelection =  Math.floor(Math.random() * (state.numberRange - 1)) + 1
          return { ...state, selection: numberSelection.toString() }
      }
      break;
    case CatActionTypes.TOGGLE_SHOW_RESULTS:
      return { ...state, showResults: !state.showResults }

    default:
      return state
  }
}

export type CatPageState = typeof initialState
export default catPageReducer