import React from 'react'
import * as _ from 'lodash'
import { ReactComponent as ClearSelectionIcon } from '../../../static/svg/outline-clear-24px.svg'
import styled from 'styled-components';

const CatTable = ({ tableHeaders, tableData, caption, lastButton, className, oneIndex }: TableProps) => {
  return (
    <table className={`table ${className}`}>
      {caption ? <caption>{caption}</caption> : null}
      <thead>
        <tr>
          <th scope="col">#</th>
          {
            _.map(tableHeaders, (header, index) => {
              return <th key={index} scope="col">{_.capitalize(header)}</th>
            })
          }
          {lastButton ? <th scope="col">Remove</th> : null}
        </tr>
      </thead>
      <tbody>
        {
          _.map(tableData, (row, index) => {
            return (
              <tr key={index}>
                <th scope="row">{oneIndex ? index + 1 : index}</th>
                {
                  _.map(tableHeaders, (header, index) => {
                    return <td key={index}>{row[header]}</td>
                  })
                }
                {
                  lastButton ?
                    <td><StyledClearSelectionIcon
                      onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => lastButton.onClick(event, row, index)}
                    ></StyledClearSelectionIcon></td> : null
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
  )
}

const StyledClearSelectionIcon = styled(ClearSelectionIcon)`
  color: red;
`

export type TableProps = {
  tableHeaders: string[]
  tableData: Array<{ [propName: string]: any }>,
  caption?: string,
  lastButton?: {
    onClick: (event: any, row: any, index: number) => any,
    text: string
  },
  className?: string,
  oneIndex?: boolean
}

export default CatTable
