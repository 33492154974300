const homeTitleList = [
  'Devourer of Jacob\'s Weetameel',
  'Dominator of value KFC deals',
  'Ingurgitator of Common Snacks',
  'Prowler of all foods cheap',
  'Partaker of all meats juicy'
]

const getHomeTitle = () => {
  const number = Math.floor(Math.random() * (homeTitleList.length))
  return homeTitleList[number]
}

export { homeTitleList, getHomeTitle }