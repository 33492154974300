import React from 'react'
import styled from 'styled-components';

const RandomizerResult = (props: RandomizerResultProps) => {
  const { className, showResults, results } = props

  return (
    <StyledRandomizerResult className={`randomizer-result d-flex ${className}`}>
      <div className={`results-intro-text ${showResults ? 'active' : 'inactive'}`} >Bongo Cat whispers...  </div>
      <div className={`results-text ml-1 ${showResults ? 'active' : 'inactive'}`}>{results}</div>
    </StyledRandomizerResult>
  )
}

const StyledRandomizerResult = styled.div`
  display: block;
  height: 50px;
  width: 100%;
  margin-bottom: 10px;

  .results-intro-text {

    &.active {
      visibility: visible;
      -webkit-animation: fadeIn ease-out 1.5s;
      animation: fadeIn ease-out 1.5s;
    }

    &.inactive {
      visibility: hidden;
      -webkit-animation: fadeOut ease-out 0.4s;
      animation: fadeOut ease-out 0.4s;
    }
  }

  .results-text {

    &.active {
      opacity: 0;
      -webkit-animation: fadeIn 1.5s ease-out 1s forwards;
      animation: fadeIn 1.5s ease-out 1s forwards;
    }

    &.inactive {
      visibility: hidden;
      -webkit-animation: fadeOut ease-out 0.4s;
      animation: fadeOut ease-out 0.4s;
    }
  }


  @keyframes fadeIn{
    0% { opacity: 0 }
    100% { opacity: 1 }
  }

  @keyframes fadeOut{
    0% { opacity: 0 }
    100% { opacity: 1 }
  }
`

interface RandomizerResultProps {
  results: string;
  showResults: boolean;
  className?: string;
}

export default RandomizerResult
